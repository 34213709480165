import axios from "axios";
import { TokenService } from "./storage.service";
import { store } from "./../store";
import {SETTINGS} from "@/constants";

// import authTypes from "@modules/auth/store/types";
class ApiService {
  constructor() {
    this._401interceptor = null;
    this.init(SETTINGS.BASE_GATEWAY_URL);
    this.getSetTokenAndMountInterceptor();
  }

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  }

  getSetTokenAndMountInterceptor() {
    let token = TokenService.getToken();
    if (token) {
      this.setHeader();
    }
    if (!this._401interceptor && token) {
      this.mount401Interceptor();
    }
  }

  setHeader() {
    axios.defaults.headers.common["Authorization"] = TokenService.getToken();
  }

  removeHeader() {
    axios.defaults.headers.common = {};
  }

  get(resource) {
    return axios.get(resource);
  }

  post(resource, data, config) {
    return axios.post(resource, data, config);
  }

  put(resource, data) {
    return axios.put(resource, data);
  }

  delete(resource) {
    return axios.delete(resource);
  }

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data);
  }

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const reqStatusHttpCode = error.request.status;
        const isTokenExpire = reqStatusHttpCode == 401;
        if (isTokenExpire) {
          store.dispatch("user/handleLogOut");
        } else return error;
      }
    );
  }

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  }
}

export default new ApiService();
