<template>
  <div>
    <FormBuilder ref="loginForm" :fields="fields" @submit="submit" />
    <div
      v-if="signInError"
      class="flow-grid-column padding-10"
      label="cloud services"
    >
      {{ signInErrorMsg }}
    </div>
    <Button
      :loading="isLoading"
      :type="loginbuttonType"
      class="margin-top-20"
      fullWidth
      size="normal"
      @click="reviewAndSubmit"
    >
      LOG IN
    </Button>
    <Button
      :loading="false"
      :type="passwordforgotbuttonType"
      class="margin-top-20"
      fullWidth
      size="small"
      @click="redirectToPasswordForgot"
    >
      Forgot Password ?
    </Button>
  </div>
</template>

<script>
import { Button, FormBuilder } from "@cldcvr/flow";

export default {
  name: "SignInEmailPasswordForm",
  components: {
    FormBuilder,
    Button
  },
  data() {
    return {
      isLoading: false,
      signInError: false,
      signInErrorMsg: "",
      loginbuttonType: "primary",
      signupbuttonType: "primary",
      passwordforgotbuttonType: "secondary",
      fields: [
        {
          type: "text",
          id: "gdp-username",
          name: "username",
          label: "username",
          placeholder: "enter username",
          rules: "required"
        },
        {
          type: "password",
          id: "gdp-password",
          name: "password",
          label: "password",
          placeholder: "enter password",
          rules: "required"
        }
      ]
    };
  },
  computed: {},
  methods: {
    async submit({ valid, values }) {
      if (!valid) return;
      this.isLoading = true;
      try {
        await this.$store.dispatch("user/signInUsingEmailPassword", {
          username: values.username,
          password: values.password
        });
        // if (res.nextStep && res.nextStep == "NEW_PASSWORD_REQUIRED") {
        //   await this.$store.dispatch("user/newLoginPassword", {
        //     username: values.username,
        //     loginSession: res.loginSession,
        //     newPassword: "Cldcvr@!23",
        //   });
        // }
        this.$router.push("/dashboard");
      }
      catch (err) {
        console.error(err.message);
        this.signInError = true;
        this.signInErrorMsg = err.message;
        this.loginbuttonType = "error";
      }
      finally {
        this.isLoading = false;
      }
    },
    async reviewAndSubmit() {
      await this.$refs.loginForm.validateAndSubmit(); // FORM Builder emits submit event
    },
    // redirectToSignUp() {
    //   this.$router.push("/signup"); // FORM Builder emits submit event
    // },
    redirectToPasswordForgot() {
      this.$router.push("/forgot-password"); // FORM Builder emits submit event
    }
  }
};
</script>

<style scoped>
.margin-top-20 {
  margin-top: 20px;
}
</style>
