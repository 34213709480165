<template>
  <AuthWrapper header="Reset Password">
    <ResetPasswordForm />
  </AuthWrapper>
</template>

<script>
import ResetPasswordForm from "../components/ResetPasswordForm";
import AuthWrapper from "./AuthWrapper";

export default {
  name: "ResetPassword",
  components: {
    ResetPasswordForm,
    AuthWrapper,
  },
};
</script>