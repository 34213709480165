<template>
  <div class="width-100-per height-100-per margin-tp-30">
    <Loader v-if="!shimmerLoader && loading">
      <p class="paragraph-2 fc-light">Loading...</p>
    </Loader>
    <div class="flow-grid-table gap-8 pop-over-in-table">
      <div class="flow-grid-row flow-grid-row-header">
        <div
          v-if="checkable"
          :style="{ justifyContent: 'center' }"
          class="flow-grid-column width-100"
        >
          <Checkbox
            :checked="allRowsChecked"
            size="small"
            state="primary"
            @click="toggleAllRowsChecked"
          >
          </Checkbox>
        </div>
        <template v-for="(col, index) in columns">
          <div :key="col.title + index" class="flow-grid-column text-breakall">
            <!-- @slot col-header with props {col, colIndex} -->
            <slot :col="col" :index="index" name="col-header">
              <p class="fc-light paragraph-2 upper-case">{{ col.title }}</p>
              <div
                class="
                  display-flex
                  flex-d-c
                  j-c-c
                  align-items-center
                  cursor-pointer
                "
                @click.stop="
                  sortColumn(
                    index,
                    col.title,
                    col.sortKey,
                    innerOrder === 'asc' ? 'desc' : 'asc'
                  )
                "
              >
                <Icon
                  :effects="false"
                  :rotate="180"
                  :state="
                    innerSort === col.title && innerOrder === 'asc'
                      ? 'primary'
                      : 'font-light'
                  "
                  name="filled-arrow"
                  size="xx-small"
                  type="filled"
                />
                <Icon
                  :effects="false"
                  :state="
                    innerSort === col.title && innerOrder === 'desc'
                      ? 'primary'
                      : 'font-light'
                  "
                  name="filled-arrow"
                  size="xx-small"
                  type="filled"
                />
              </div>
            </slot>
          </div>
        </template>

        <!-- Last column is BLANK for cells with row toggle -->
        <template v-if="!disableRowExpansionToggle">
          <slot name="actions-col-header">
            <div class="flow-grid-column"></div>
          </slot>
        </template>
      </div>
      <Divider :size="1" state="default" type="solid" />
    </div>
    <div class="flow-grid-table gap-8 pop-over-in-table scroll">
      <!-- COLULM HEADERS -->

      <!-- COLULM HEADERS -->

      <!-- SHIMMER LOADER -->
      <template v-for="i in loaderRows">
        <div
          v-if="shimmerLoader && loading"
          :key="`c_${i}`"
          class="flow-grid-row no-hover"
          filled
          state=""
          stateType="solid"
        >
          <template v-for="j in columns.length">
            <div :key="`c_${j}`" :label="columns[j]" class="flow-grid-column">
              <Shimmer :height="shimmerHeight" :width="shimmerWidth" />
            </div>
          </template>
          <!-- EMPTY COLUMN PLACEHOLDER FOR CHEVRON ICON -->
          <div v-if="!disableRowExpansionToggle" class="flow-grid-column"></div>
        </div>
      </template>
      <!-- SHIMMER LOADER -->

      <!-- ROW SEGMENT -->
      <template v-for="(row, rowIndex) in filteredRows">
        <!-- The "open" attr here only adds border radius to bottom lt & rt sides when true. Very subtle. Nice 🤑-->
        <div
          v-if="!loading"
          :key="`row-${rowIndex}`"
          :open="row.isRowExpanded"
          :state="row.coolRedVariant ? 'error' : ''"
          class="flow-grid-row no-hover bar-height-6"
          filled
          stateType="solid"
        >
          <!-- ROW DATA -->
          <!-- @slot row with props {row, rowIndex} -->
          <slot :row="row" :rowIndex="rowIndex" name="row">
            <div
              v-if="checkable"
              :key="`checkbox-row-${rowIndex}`"
              :style="{ justifyContent: 'center' }"
              class="flow-grid-column width-100"
            >
              <Checkbox
                :checked="row.checked"
                size="small"
                state="primary"
                @click="rowChecked(row)"
              >
              </Checkbox>
            </div>
            <template v-for="(cell, cellIndex) in row.cells">
              <!-- The label attr on a "div.flow-grid-column" ensures the column names are seen nicely on smaller screens as well -->
              <div
                :key="`row-${rowIndex}-cell-${cellIndex}`"
                :label="columns[cellIndex]"
                class="flow-grid-column text-breakall"
              >
                <!-- @slot cell with props {cell, cellIndex, rowIndex, row} -->
                <slot
                  :cell="cell"
                  :cellIndex="cellIndex"
                  :row="row"
                  :rowIndex="rowIndex"
                  name="cell"
                >
                  <p :class="[fontSizeClass]" class="fc-dark">
                    {{ cell }}
                  </p>
                </slot>
              </div>
            </template>

            <!-- There shall always be the last cell for dropdown toggle unless disableRowExpansionToggle===true -->
            <template>
              <div :key="rowIndex" class="flow-grid-column">
                <template>
                  <!-- @slot row-actions with props {row, rowIndex} -->
                  <!-- If you override, you must handle row expansion by yourself -->
                  <slot :row="row" :rowIndex="rowIndex" name="row-actions">
                    <div>
                      <Icon
                        v-if="!row.zipLoading"
                        :state="row.zipType"
                        name="download"
                        size="medium"
                        @click="downloadZip(row)"
                      />
                      <Loader v-else class="loader" />
                    </div>
                    <div class="padding-left-30">
                      <Icon
                        v-if="!row.pdfLoading"
                        :state="row.pdfType"
                        name="i-pdf"
                        size="medium"
                        @click="downloadPDF(row)"
                      />
                      <Loader v-else class="loader" />
                    </div>
                    <div class="padding-left-30">
                      <Icon
                        name="i-info-fill"
                        size="medium"
                        state="primary"
                        @click="openSlideout(row)"
                      />
                    </div>
                    <!-- This slot can be used to add a few more icons/button to the last column of each row -->
                  </slot>
                </template>
              </div>
            </template>
          </slot>
          <!-- ROW DATA -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Checkbox, Divider, Icon, Loader, Shimmer } from "@cldcvr/flow";

export default {
  name: "GoodPackTable",
  components: {
    Icon,
    Loader,
    Divider,
    Checkbox,
    Shimmer
  },
  props: {
    noPagination: { type: Boolean, default: false },
    headingText: { type: String, default: null },
    /** Only works if cells are simple. TODO: Add filter ovveride func as prop if need be */
    searchable: { type: Boolean, default: false },
    headingBorder: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    /** Now of shimmer rows to be shown at the time of loading */
    loaderRows: { type: Number, default: 3 },
    shimmerLoader: { type: Boolean, default: true },
    /** Define width and height of shimmer row cell value can be in % or px */
    shimmerWidth: { type: String, default: "80%" },
    shimmerHeight: { type: String, default: "100%" },
    /** Makes each row checkable. ENSURE you send a checked boolean flag in every row, if you set this as true! */
    checkable: { type: Boolean, default: false },
    size: {
      type: String,
      default: "normal",
      validator: (val) => ["normal", "small"].includes(val)
    },
    /**
     * Array of column names.
     * It can be any custom Array<any>, as long as you override both the slots of col-header and row
     * (Default is Array<string>)
     */
    columns: { type: Array, default: () => ["COL 1", "COL 2"] },
    /**
     * Array of objects that must have the cells key which contains an array.
     * Cells can be any custom Array[any], as long as you override the slots of row or cell.
     * (Default is Array<{cells: Array[string] }>)
     * Custom options:
     *  - **row.isRowExpanded:** [Boolean] Controls initial state of row being expanded.
     *  - **row.disableRowExpansion:** [Boolean] Will hide row expansion action for current row
     *  - **row.coolRedVariant:** [Boolean] Gives the row a cool red tinge
     */
    rows: {
      type: Array,
      default: () => [
        {
          id: 0,
          cells: [
            "Cell 1",
            "DROPDOWN_OPTIONS, DROPDOWN_OPTIONS, DROPDOWN_OPTIONS, DROPDOWN_OPTIONS"
          ],
          checked: false,

          // disableRowExpansion: true,
          coolRedVariant: true, // This gives a red tinge to the row
          isRowExpanded: false
        }
      ],
      validator: (rowArr) => rowArr.every((row) => Array.isArray(row.cells))
    },
    /**
     * When set to true, no row shall have the toggle expansion panel option.
     */
    disableRowExpansionToggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filteredRows: [],
      allRowsChecked: false,
      innerOrder: "",
      innerSort: ""
    };
  },
  computed: {
    fontSizeClass() {
      return this.size === "normal" ? "paragraph-1" : "paragraph-2";
    }
  },
  watch: {
    deep: true,
    immediate: true,
    rows(newRows) {
      this.filteredRows = newRows;
      this.syncAllRowsChecked();
    }
  },
  mounted() {
    this.filteredRows = this.rows;
    this.syncAllRowsChecked();
  },
  methods: {
    _downloadURI(uri) {
      var link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadPDF(row) {
      try {
        row.pdfLoading = true;
        const payload = {};
        const payloadArr = [];
        payload["Barcode1D"] = row.cells[1];
        payload["Date_Time"] = row.cells[0];
        payloadArr.push(payload);
        const URL = await this.$store.dispatch(
          "dashboard/downloadPDF",
          payloadArr
        );
        if (URL) {
          this._downloadURI(URL);
          row.pdfType = "primary";
        }
      }
      catch (err) {
        console.error(err);
        row.pdfType = "error";
      }
      finally {
        row.pdfLoading = false;
      }
    },
    async downloadZip(row) {
      try {
        row.zipLoading = true;
        const payload = {};
        payload["Barcode1D"] = row.cells[1];
        payload["Date_Time"] = row.cells[0];
        const URL = await this.$store.dispatch(
          "dashboard/downloadZip",
          payload
        );
        if (URL) {
          this._downloadURI(URL.body);
          row.zipType = "primary";
        }
      }
      catch (err) {
        console.error(err);
        row.zipType = "error";
      }
      finally {
        row.zipLoading = false;
      }
    },
    syncAllRowsChecked() {
      this.allRowsChecked = this.filteredRows.every((row) => row.checked);
      const selectedRows = this.filteredRows.reduce((count, row) => {
        row.checked && count++;
        return count;
      }, 0);
      this.$store.dispatch("dashboard/updateSelectedRows", selectedRows);
    },
    rowChecked(row) {
      row.checked = !row.checked;
      this.syncAllRowsChecked();
      this.$emit("rowChecked", row);
    },
    toggleAllRowsChecked() {
      let count = 0;
      this.allRowsChecked = !this.allRowsChecked;
      for (let row of this.filteredRows) {
        row.checked = this.allRowsChecked;
        count++;
      }
      if (!this.allRowsChecked) count = 0;
      this.$store.dispatch("dashboard/updateSelectedRows", count);
    },
    sortColumn(idx, column, sortKey, order) {
      this.innerSort = column;
      this.innerOrder = order;

      // const idx = this.getColumnIndex(column);
      let sortedArr = JSON.parse(JSON.stringify(this.filteredRows));

      sortedArr.sort((a, b) => {
        if (a instanceof Date) {
          return new Date(b) - new Date(a);
        }
        let first = !sortKey ? a.cells[idx] || "" : a.cells[idx][sortKey] || "";
        let second = !sortKey
          ? b.cells[idx] || ""
          : b.cells[idx][sortKey] || "";

        if (first.valueOf() < second.valueOf()) return order === "asc" ? -1 : 1;
        if (first.valueOf() > second.valueOf()) return order === "asc" ? 1 : -1;
        return 0;
      });

      this.filteredRows = sortedArr;
    },

    openSlideout(row) {
      var wall_images = row.meta.wall_images;
      var wall_images_result = Object.entries(wall_images);
      let sorted_wall_images = wall_images_result.sort((a, b) => {
        return a[0].charAt(5) - b[0].charAt(5);
      });
      const slideoutDetails = {
        details: [
          {
            key: "ID",
            value: row.cells[1]
          },
          {
            key: "Date",
            value: row.cells[0]
          },
          {
            key: "Depot",
            value: row.cells[2]
          }
        ],
        images: sorted_wall_images
        // images: row.meta.wall_images,
      };
      this.$store.dispatch("dashboard/openSlideout", slideoutDetails);
    }
  }
};
</script>

<style scoped>
/* TODO: Remove after flow version > v4.3.2 */
div.flow-grid-table > div:before,
div.flow-grid-table > div.flow-grid-row:before {
  pointer-events: none;
}

.padding-left-30 {
  padding-left: 30px;
}

.scroll {
  height: 60vh;
  overflow-y: scroll;
}

.flow-pagination-container {
  display: flex;
  z-index: 90;
  border-radius: 8px 8px 0 0;
  background-color: var(--dashboard-element-light);
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 24px;
  bottom: 0;
}

.loader {
  padding: 0px;
}
</style>
