<template>
  <div>
    <IsDevChip/>
    <AuthWrapper header="Log In">
      <SignInEmailPasswordForm/>
    </AuthWrapper>
  </div>
</template>

<script>
import SignInEmailPasswordForm from "../components/SignInEmailPasswordForm";
import AuthWrapper from "./AuthWrapper";
import IsDevChip from "@/components/IsDevChip";
// import {UserService} from "@/services/storage.service";

export default {
  name: "Login",
  components: {
    SignInEmailPasswordForm,
    AuthWrapper,
    IsDevChip,
  },
  // UnComment this to fix/stop login navigation after user is logged in
  // mounted() {
  //   if (UserService.getUserProfile()?.userId) {
  //     this.$router.push("/dashboard");
  //   }
  // },
};
</script>
