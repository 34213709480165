const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
const {MFA_POPUP_MESSAGE, MFA_POPUP_INPUT_HINT} = require("@/constants");

/**
 * Login user
 *
 * @param {*} poolData
 * @param {{username, password}} body
 * @param {*} cb
 */
function login(poolData, body, cb) {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const { username, password } = body;
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );
  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess(res) {
      const data = {
        refreshToken: res.getRefreshToken().getToken(),
        accessToken: res.getAccessToken().getJwtToken(),
        accessTokenExpiresAt: res.getAccessToken().getExpiration(),
        idToken: res.getIdToken().getJwtToken(),
        idTokenExpiresAt: res.getAccessToken().getExpiration(),
      };
      cb(null, data);
    },
    customChallenge: function () {
      let challengeAnswer = prompt(MFA_POPUP_MESSAGE, MFA_POPUP_INPUT_HINT);
      cognitoUser.sendCustomChallengeAnswer(challengeAnswer, this);
    },
    onFailure(err) {
      cb(err);
    },
    mfaRequired() {
      const data = {
        nextStep: "MFA_AUTH",
        loginSession: cognitoUser.Session,
      };
      cb(null, data);
    },
    newPasswordRequired() {
      const data = {
        nextStep: "NEW_PASSWORD_REQUIRED",
        loginSession: cognitoUser.Session,
      };
      cb(null, data);
    },
  });
}

module.exports = login;
