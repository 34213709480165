const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const USER_PROFILE = "user_profile";
const ACTIVE_ORG_KEY = "active_org";

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  clearLocalSession() {
    localStorage.clear();
  },
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
};

const UserService = {
  saveUserProfile(profile) {
    localStorage.setItem(USER_PROFILE, JSON.stringify(profile));
  },

  getUserProfile() {
    let userProfile = localStorage.getItem(USER_PROFILE);
    return JSON.parse(userProfile);
  },

  removeUserProfile() {
    localStorage.removeItem(USER_PROFILE);
  },
};

const OrgService = {
  getActiveOrg() {
    return sessionStorage.getItem(ACTIVE_ORG_KEY);
  },

  saveActiveOrg(orgId) {
    sessionStorage.setItem(ACTIVE_ORG_KEY, orgId);
  },

  removeActiveOrg() {
    sessionStorage.removeItem(ACTIVE_ORG_KEY);
  },
};

export { TokenService, UserService, OrgService };
