export function isSlideoutOpen(state) {
  return state.isSlideoutOpen;
}

export function isImageModalOpen(state) {
  return state.isImageModalOpen;
}

export function getTableData(state) {
  return state.tableData;
}

export function getNotFoundMsg(state) {
  return state.notFoundMsg;
}

export function getPageSize(state) {
  return state.pageSize;
}

export function getStartKey(state) {
  return state.startKey;
}

export function getSlideoutDetails(state) {
  return state.slideoutDetails;
}

export function getSelectedImage(state) {
  return state.selectedImage;
}
export function getSelectedRowsCount(state) {
  return state.selectedRowsCount;
}

export function isNextEnable(state) {
  return state.isNextEnable;
}
