import authService from "../../services/auth/auth.service";
import { AuthWithCognito } from "../../components/auth";
import router from "../../router";

export function handleLogin({ commit }, value) {
  commit("handleLogin", value);
}

export async function signInUsingEmailPassword({ commit }, payload) {
  const res = await authService.signInUsingEmailPassword(payload);
  if (res.nextStep && res.nextStep === "NEW_PASSWORD_REQUIRED") {
    AuthWithCognito.loginNewPasswordRequired({
      username: payload.username,
      loginSession: res.loginSession,
      newPassword: "Cldcvr@!23",
    });
  }
  await commit("handleLogin", true);
  return res;
}

export async function signUpUsingEmailPassword({ commit }, payload) {
  const res = await authService.signUpUsingEmailPassword(payload);
  // if (res.nextStep && res.nextStep == "NEW_PASSWORD_REQUIRED") {
  //   AuthWithCognito.loginNewPasswordRequired({
  //     username: payload.username,
  //     loginSession: res.loginSession,
  //     newPassword: "Cldcvr@!23",
  //   });
  // }
  await commit("handleLogin", false);
  return res;
}

export async function forgotPassword({ commit }, payload) {
  const res = await authService.forgotPassword(payload);
  // if (res.nextStep && res.nextStep == "NEW_PASSWORD_REQUIRED") {
  //   AuthWithCognito.loginNewPasswordRequired({
  //     username: payload.username,
  //     loginSession: res.loginSession,
  //     newPassword: "Cldcvr@!23",
  //   });
  // }
  await commit("handleLogin", false);
  return res;
}

export async function resetPassword({ commit }, payload) {
  const res = await authService.resetPassword(payload);
  // if (res.nextStep && res.nextStep == "NEW_PASSWORD_REQUIRED") {
  //   AuthWithCognito.loginNewPasswordRequired({
  //     username: payload.username,
  //     loginSession: res.loginSession,
  //     newPassword: "Cldcvr@!23",
  //   });
  // }
  await commit("handleLogin", false);
  return res;
}

export async function handleLogOut({ commit }) {
  await authService.resetUserAndHeaders();
  await commit("handleLogin", false);
  await commit("dashboard/setTableData", [], { root: true });
  router.push("/");
}
