import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "./../components/auth/views/Login.vue";
import SignUp from "./../components/auth/views/SignUp.vue";
import ForgotPassword from "./../components/auth/views/ForgotPassword.vue";
import ResetPassword from "./../components/auth/views/ResetPassword.vue";
import { TokenService } from "./../services/storage.service";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    meta: {
      title: "Dashboard",
      protected: true,
    },
  },
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      title: "Login",
      isLoginOrLogout: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    meta: {
      title: "Signup",
      protected: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotpassword",
    component: ForgotPassword,
    meta: {
      title: "ForgotPassword",
    },
  },
  {
    path: "/reset-password",
    name: "resetpassword",
    component: ResetPassword,
    meta: {
      title: "ResetPassword",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = TokenService.getToken() || false;
  document.title = to.meta.title;
  if (to.isLoginOrLogout) {
    return next();
  } else if (to.meta.protected && !isLoggedIn) {
    return next({
      name: "login",
      query: { redirect: to.fullPath },
    });
  } else {
    return next();
  }
});

export default router;
