<template>
  <AuthWrapper header="Sign Up">
    <SignUpEmailPasswordForm />
  </AuthWrapper>
</template>

<script>
import SignUpEmailPasswordForm from "../components/SignUpEmailPasswordForm";
import AuthWrapper from "./AuthWrapper";
import { UserService } from "@/services/storage.service";

export default {
  name: "SignUp",
  components: {
    SignUpEmailPasswordForm,
    AuthWrapper,
  },
  mounted() {
      if (
        UserService.getUserProfile().userId.toLowerCase() !==
        "PhotoQCAdmin@goodpack.com".toLowerCase()
      ) {
        this.$router.push("/dashboard");
      }
  },
};
</script>
