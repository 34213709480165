import Vue from "vue";
import Vuex from "vuex";
import dashboard from "./dashboard";
import user from "./user";
Vue.use(Vuex);

// export default function (/* { ssrContext } */) {
export const store = new Vuex.Store({
  modules: {
    dashboard,
    user,
  },

  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEV,
});

// return Store;
// }
