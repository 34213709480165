// import AuthenticationError from "./AuthenticationError";
import ApiService from "../api.service";
import { TokenService, UserService } from "../storage.service";
import { AuthWithCognito } from "../../components/auth";

const authService = {
  signInUsingEmailPassword(body) {
    return new Promise((resolve, reject) => {
      AuthWithCognito.login(body, (err, response) => {
        if (err) reject(err);
        const userId = body.username;
        this.setUserAndHeaders(response, userId);
        ApiService.mount401Interceptor();
        ApiService.setHeader();
        resolve(response);
      });
    });
  },
  signUpUsingEmailPassword(body) {
    return new Promise((resolve, reject) => {
      AuthWithCognito.signup(body, (err, response) => {
        if (err) reject(err);
        // const userId = body.username;
        // this.setUserAndHeaders(response, userId);
        // ApiService.mount401Interceptor();
        // ApiService.setHeader();
        resolve(response);
      });
    });
  },
  forgotPassword(body) {
    return new Promise((resolve, reject) => {
      AuthWithCognito.passwordForgot(body, (err, response) => {
        if (err) reject(err);
        // const userId = body.username;
        // this.setUserAndHeaders(response, userId);
        // ApiService.mount401Interceptor();
        // ApiService.setHeader();
        resolve(response);
      });
    });
  },
  resetPassword(body) {
    return new Promise((resolve, reject) => {
      AuthWithCognito.passwordReset(body, (err, response) => {
        if (err) reject(err);
        // const userId = body.username;
        // this.setUserAndHeaders(response, userId);
        // ApiService.mount401Interceptor();
        // ApiService.setHeader();
        resolve(response);
      });
    });
  },
  setUserAndHeaders(res, userId) {
    TokenService.saveToken(res.idToken);
    TokenService.saveRefreshToken(res.refreshToken);
    UserService.saveUserProfile({
      userId,
    });
  },
  resetUserAndHeaders() {
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
    TokenService.clearLocalSession();
  },
};

export default authService;
