export default function () {
  return {
    isSlideoutOpen: false,
    isImageModalOpen: false,
    tableData: [],
    notFoundMsg: "",
    startKey: [{}],
    searchPayload: {},
    slideoutDetails: {},
    selectedImage: {},
    selectedRowsCount: 0,
    pageSize: 200,
    isNextEnable: true,
  };
}
