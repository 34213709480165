<template>
  <div>
    <template v-if="!isNotFoundMsg">
      <h1 class="paragraph-2 fc-light margin-top-20">
        {{ notFoundMsg }}
      </h1>
    </template>
    <template v-if="isTableData">
      <GoodPackTable
        :columns="schemaColumns"
        :loaderRows="noRecords"
        :loading="isLoading"
        :rows="schemaRows"
        checkable
      />
      <div class="display-flex margin-top-20 flex-1">
        <Pagination
          :loading="isLoading"
          :max="noRecords"
          :offset.sync="poffset"
          :state="paginationState"
          :total="400"
          position="inherit"
          @next="handleNext(...arguments)"
          @prev="handlePrev(...arguments)"
        />
        <div class="padding-10">
          <Slab v-if="selectedRowsCount" :size="'small'">
            <template #primary-action>
              <Icon name="close" size="small" type="outlined"></Icon>
            </template>
            {{ selectedRowsCount }} Selected
            <template>
              <Button
                :disabled="pdfButton.loading"
                :type="pdfButton.type"
                class="margin-left-10"
                size="small"
                @click="downloadPDF"
              >
                Export PDF
              </Button>
              <Button
                :disabled="csvButton.loading"
                :type="csvButton.type"
                class="margin-left-10"
                size="small"
                @click="exportCSV"
              >
                Export CSV
              </Button>
            </template>
          </Slab>
        </div>
      </div>
    </template>
    <template v-else-if="!isTableData">
      <h1 class="paragraph-2 fc-light margin-top-20">
        No data present. Please search with different combinations .
      </h1>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GoodPackTable from "./GoodPackTable.vue";
import { Button, Icon, Slab } from "@cldcvr/flow";
import downloadCsv from "download-csv";
// import { PAGE_SIZE } from "../constants";
import Pagination from "./dashboard/Pagination.vue";

export default {
  name: "TableData",
  components: {
    GoodPackTable,
    Pagination,
    Slab,
    Button,
    Icon,
  },
  data() {
    return {
      paginationState: "primary",
      isLoading: false,
      poffset: 0,
      pdfButton: {
        loading: false,
        type: "primary",
      },
      csvButton: {
        loading: false,
        type: "primary",
      },
      schemaColumns: [
        { title: "Date", sortable: true },
        { title: "1d barcode", sortable: true },
        { title: "depot", sortable: true },
        { title: "images", sortable: true },
        { title: "email", sortable: true },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tableData: "dashboard/getTableData",
      notFoundMsg: "dashboard/getNotFoundMsg",
      selectedRowsCount: "dashboard/getSelectedRowsCount",
      pageSize: "dashboard/getPageSize",
    }),
    noRecords() {
      return this.pageSize;
    },
    isTableData() {
      return this.tableData.length !== 0;
    },
    isNotFoundMsg() {
      return this.notFoundMsg === "";
    },
    schemaRows() {
      // update selected rows count number to 0
      this.$store.dispatch("dashboard/updateSelectedRows", 0);
      return this.tableData.map((table) => ({
        cells: [
          table.Date_Time,
          table.Barcode1D,
          table.DepotID,
          table.no_of_images,
          table.user_email,
        ],
        checked: false,
        isRowExpanded: false,
        pdfLoading: false,
        pdfType: "primary",
        zipLoading: false,
        zipType: "primary",
        meta: {
          wall_images: table.wall_images,
        },
      }));
    },
  },
  methods: {
    _downloadURI(uri) {
      const link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadPDF() {
      try {
        this.pdfButton.loading = true;
        const selectedRows = this.schemaRows.reduce((arr, row) => {
          row.checked &&
            arr.push({
              Date_Time: row.cells[0],
              Barcode1D: row.cells[1],
            });
          return arr;
        }, []);
        const URL = await this.$store.dispatch(
          "dashboard/downloadPDF",
          selectedRows
        );
        this._downloadURI(URL);
        this.pdfButton.type = "primary";
      } catch (err) {
        console.error(err);
        this.pdfButton.type = "error";
      } finally {
        this.pdfButton.loading = false;
      }
    },

    async exportCSV() {
      try {
        this.csvButton.loading = true;
        const selectedRows = this.schemaRows.reduce((arr, row) => {
          row.checked &&
            arr.push({
              Date: row.cells[0].slice(0, 10),
              Time: row.cells[0].slice(11),
              Barcode1D: row.cells[1],
              Depot: row.cells[2],
              Images: row.cells[3],
              Email: row.cells[4],
            });
          return arr;
        }, []);
        await downloadCsv(selectedRows);
        this.csvButton.type = "primary";
      } catch (err) {
        console.error(err);
        this.csvButton.type = "error";
      } finally {
        this.csvButton.loading = false;
      }
    },
    async handleNext(newoffset) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "dashboard/handleNextPage",
          newoffset / this.pageSize
        );
        this.paginationState = "primary";
      } catch (err) {
        console.error(err);
        this.paginationState = "error";
      } finally {
        this.isLoading = false;
      }
    },
    async handlePrev(newoffset) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "dashboard/handlePrevPage",
          newoffset / this.pageSize
        );
        this.paginationState = "primary";
      } catch (err) {
        console.error(err);
        this.paginationState = "error";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.margin-left-10 {
  margin-left: 10px;
  min-width: fit-content;
}

.margin-top-20 {
  margin-top: 20px;
}
</style>
