<template>
  <AuthWrapper header="Forgot Password">
    <ForgotPasswordForm />
  </AuthWrapper>
</template>

<script>
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import AuthWrapper from "./AuthWrapper";

export default {
  name: "ForgotPassword",
  components: {
    ForgotPasswordForm,
    AuthWrapper,
  },
};
</script>