export const IS_DEV = false;

// New Dev Environment User Pool & Gateway URl Configuration settings
const DEV_SETTINGS = {
    USER_POOL_CONFIG: {
        UserPoolId: "us-east-2_VvOgC3oCb", // your user pool ID
        ClientId: "1qpqpia567haciagvgda4qgt88", // your "App client ID"
    },
    BASE_GATEWAY_URL:
        "https://o8rikhbb4i.execute-api.us-east-2.amazonaws.com/test/goodpack-us",
    PDF_DOWNLOAD_FUNCTION_URL: 'https://5dn3gjtandcb7zbar74i4m4gqi0iwkfz.lambda-url.us-east-2.on.aws/',
    APP_NAME: "uat_app.apk",
};

// New Prod Environment User Pool Configuration & Gateway URl Configuration settings
const PROD_SETTINGS = {
    USER_POOL_CONFIG: {
        UserPoolId: "us-east-2_4gCWyH7mp", // your "User pool ID"
        ClientId: "55tfo4g4s23k7e6e30c8klh6n8", // your "App client ID"
    },
    BASE_GATEWAY_URL:
        "https://r70m37lnz6.execute-api.us-east-2.amazonaws.com/prod/goodpack-us",
    PDF_DOWNLOAD_FUNCTION_URL: 'https://6oko2oibm7tawpevhv4rzx4tnm0gwhmg.lambda-url.us-east-2.on.aws/',
    APP_NAME: "app.apk",
};

export const SETTINGS = IS_DEV ? DEV_SETTINGS : PROD_SETTINGS;

export const MFA_POPUP_MESSAGE = "Please input OTP code.";
export const MFA_POPUP_INPUT_HINT = "";
