<template>
  <div class="padding-20">
    <div class="flex">
      <SearchInput
        :placeholder="placeholder"
        :showSearchIcon="false"
        :value.sync="searchValue"
      >
        <!-- <MultiselectWithIcon iconBackground="transparent">
          <Icon name="i-search" type="filled" size="small"></Icon>
          <multiselect
            v-model="dvalue"
            :options="options"
            :multiple="false"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            :placeholder="'Search By'"
          ></multiselect>
        </MultiselectWithIcon> -->
      </SearchInput>
      <div class="padding-0">
        <multiselect
          v-model="searchType"
          :options="searchTypeOptions"
          :multiple="false"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="'Filter By'"
        ></multiselect>
      </div>
      <DateTimePicker :value.sync="startDate" placeholder="Start Date" />
      <DateTimePicker :value.sync="endDate" placeholder="End Date" />
      <div class="padding-0">
        <multiselect
          v-model="wallId"
          :options="wallIdOptions"
          :multiple="false"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="'Filter By'"
        ></multiselect>
      </div>
      <div class="padding-0">
        <multiselect
          v-model="perPageRecords"
          :options="perPageRecordsOptions"
          :multiple="false"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="'Records Per Page'"
        ></multiselect>
      </div>
      <Button
          v-if="false"
          :type="buttonType"
          size="normal"
          class="margin-left-10"
          :disabled="isLoading"
          @click="onCSV()"
      >
        ⬇ CSV
      </Button>
      <Button
        :type="buttonType"
        size="normal"
        class="margin-left-10"
        :disabled="isLoading"
        @click="onSearch(false)"
      >
        Search
      </Button>
      <Button
        type="secondary"
        size="normal"
        class="margin-left-10"
        :disabled="isLoading"
        @click="clearFilters"
      >
        Clear
      </Button>
      <Icon
        name="i-upload"
        type="filled"
        size="small"
        @click="handleClick"
        :disabled="isLoading"
      >
      </Icon>
      <input
        ref="fileInput"
        type="file"
        class="display-none"
        @input="selectFile($event)"
        accept=".csv"
      />
    </div>
    <div>
      <Loader v-if="isLoading">
        <p class="paragraph-2 fc-light">Loading...</p>
      </Loader>
      <table-data v-else />
    </div>
  </div>
</template>

<script>
import {
  SearchInput,
  // MultiselectWithIcon,
  Multiselect,
  Icon,
  Button,
  Loader,
} from "@cldcvr/flow";
import TableData from "./../components/TableDate.vue";
import DateTimePicker from "./../components/DateTimePicker.vue";
import { mapGetters } from "vuex";
import csv from "csvtojson";
// import { PAGE_SIZE } from "../constants";
export default {
  name: "Home",
  components: {
    SearchInput,
    // MultiselectWithIcon,
    Multiselect,
    Icon,
    Button,
    DateTimePicker,
    TableData,
    Loader,
  },
  data() {
    return {
      buttonType: "primary",
      isLoading: false,
      searchValue: null,
      searchList: [],
      startDate: null,
      endDate: null,
      dvalue: "1D barcode",
      options: ["1D barcode"],
      placeholder: "search with 1D",
      wallIdOptions: [
        "Wall ID:1",
        "Wall ID:2",
        "Wall ID:3",
        "Wall ID:4",
        "Interior",
      ],
      searchTypeOptions: [
        "Full 1D search",
        "Fuzzy 1D search"
      ],
      perPageRecordsOptions: [
        "200",
        "100",
        "50",
        "20",
        "10"
      ],
      wallId: "",
      searchType: "Full 1D search",
      perPageRecords: "",
      poffset: 0,
    };
  },
  computed: {
    ...mapGetters({
      tableData: "dashboard/getTableData",
    }),
    isData() {
      if (this.tableData.length != 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async selectFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      const text = await files[0].text();
      this.searchList = [];
      await csv()
        .fromString(text)
        .subscribe((json) => {
          this.searchList.push(json.Barcode1D);
        });
      this.onSearch(true);
    },
    handleClick() {
      this.$refs.fileInput.value = "";
      this.$refs.fileInput.click();
    },
    async onCSV(){
      console.log("onCSV called");
    },
    async onSearch(isCsvUpload) {
      this.isLoading = true;
      const payload = {};
      this.setPayload(payload, isCsvUpload);
      try {
        await this.$store.dispatch("dashboard/onSearch", payload);
        this.buttonType = "primary";
      } catch (err) {
        console.error(err);
        this.buttonType = "error";
      } finally {
        this.isLoading = false;
      }
    },
    clearFilters(){
      location.reload();
      // this.searchValue = "";
      // this.startDate = null;
      // this.endDate = null;
      // this.searchType = "Full 1D search";
      // this.wallId = "";
      // this.perPageRecords = "";
    },
    setPayload(payload, isCsvUpload) {
      if (!isCsvUpload) {
        if (!this.searchValue) {
          this.searchList = [];
        }
        else{
          this.searchList = this.searchValue.split(" ");
        }
      }
      payload["barcode_ids"] = this.searchList;
      // payload["no_of_records_per_page"] = PAGE_SIZE;
      payload["search_type"] = this.searchType;
      if (this.startDate) payload["start_date"] = this.startDate;
      if (this.endDate) payload["end_date"] = this.endDate;
      if (this.wallId){
        if (this.wallId === "Interior"){
          payload["wall_id"] = "Wall ID:5";
        }
        else{
          payload["wall_id"] = this.wallId;
        }
      }
      if (this.perPageRecords === ""){
        this.perPageRecords = "200"
      }
      payload["no_of_records_per_page"] = this.perPageRecords;
      payload["start_key"] = {};
    },
  },
};
</script>

<style>
.flex {
  display: flex;
}
.search-input-container[data-shape="rounded"] {
  border-radius: 18px;
  width: -webkit-fill-available;
}
.padding-0 {
  padding: 0px;
  margin-left: 5px;
}
.display-none {
  display: none;
}
.margin-left-10 {
  margin-left: 10px;
  min-width: fit-content;
}
.margin-top-20 {
  margin-top: 20px;
}
</style>
