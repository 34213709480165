<template>
  <div class="flow-pagination-container min-width-172" :position="position">
    <Icon
      name="i-chevron-left"
      type="filled"
      :state="state"
      size="small"
      :disabled="offset <= 0 || loading"
      @click="prev()"
    />
    <p v-if="!loading" class="paragraph-1 fc-normal">
      {{ label }}
    </p>
    <Icon v-if="loading" size="small" name="loading" />
    <Icon
      name="i-chevron-right"
      type="filled"
      :state="state"
      size="small"
      :disabled="!isNextEnable || loading"
      @click="next()"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Icon } from "@cldcvr/flow";

export default {
  name: "Pagination",
  components: {
    Icon,
  },
  props: {
    /**
     * Total number of results
     */
    total: {
      type: Number,
      default: 0,
    },
    state: {
      type: String,
      default: "primary",
    },
    /**
     * Maximum results to display per page
     */
    max: {
      type: Number,
      default: 0,
    },
    /**
     * Offset results (Used for pagination cursor) <br/> This is updated whenever next or previous arrow clicked.<br/>NOTE : Use 'offset.sync' for 2 way binding
     */
    offset: {
      type: Number,
      default: 0,
    },
    /**
     * By default this is displayed at bottom right of page, But if we want to display at any relative container then use 'absolute'
     */
    position: {
      type: String,
      default: "fixed",
    },
    /**
     * Display loader
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isNextEnable: "dashboard/isNextEnable",
    }),
    label() {
      const lastIndex = this.offset + this.max;
      const firstIndex = this.offset + 1;
      return `${firstIndex} - ${lastIndex}`;
    },
    isNext() {
      return !(this.isNextEnable && this.loading);
    },
  },
  methods: {
    next() {
      const nextOffset = this.offset + this.max;
      /**
       * To update offset in parent
       *
       * @event update:offset
       */
      this.$emit("update:offset", nextOffset);

      /**
       * Whenever Next is clicked.
       *
       * @event next
       */
      this.$emit("next", nextOffset);
    },
    prev() {
      const prevOffset = this.offset - this.max;
      this.$emit("update:offset", prevOffset);

      /**
       * Whenever Previous is clicked.
       *
       * @event prev
       */
      this.$emit("prev", prevOffset);
    },
  },
};
</script>
<style scoped>
div.flow-pagination-container {
  display: flex;
  z-index: 0;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--dashboard-element-light);
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px;
  bottom: 0px;
  right: 24px;
}
div.flow-pagination-container > * + * {
  margin-left: 24px;
}
div.flow-pagination-container > * + *.loading-text {
  margin-left: 8px;
}
div.flow-pagination-container[position="fixed"] {
  position: fixed;
}
div.flow-pagination-container[position="absolute"] {
  position: absolute;
}
.min-width-172 {
  min-width: 172px;
}
</style>
