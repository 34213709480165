<template>
  <div class="display-flex align-items-center flex-gap-14 flex-1">
    <!-- Logo -->
    <img src="../../assets/logo-new.png" class="padding-12" alt="logo" />
    <div class="portal-name">Photo QC Portal</div>
    <IsDevChip/>
    <!-- User Logout button -->
    <UserProfilePopover class="margin-lt-auto padding-rt-8" />
  </div>
</template>

<script>
import UserProfilePopover from "./../User/UserProfilePopover.vue";
import IsDevChip from "@/components/IsDevChip";

export default {
  name: "CoreHeader",
  components: { IsDevChip, UserProfilePopover },
};
</script>

<style>
.padding-12 {
  height: 50px;
}

.portal-name {
  margin-left: 443px;
  color: #0066ff;
  font-weight: 600;
  font-size: 160%;
}
</style>
