<template>
  <div>
    <FormBuilder ref="resetPasswordForm" :fields="fields" @submit="submit" />
    <div
      v-if="resetPasswordError"
      class="flow-grid-column padding-10"
      label="cloud services"
    >
      {{ resetPasswordErrorMsg }}
    </div>
    <Button
      :loading="isLoading"
      :type="buttonType"
      class="margin-top-20"
      fullWidth
      size="normal"
      @click="reviewAndSubmit"
    >
      Reset password
    </Button>
    <Button
      :loading="false"
      :type="loginbuttonType"
      class="margin-top-20"
      size="normal"
      @click="redirectToLogin"
    >
      Go to Login Page
    </Button>
  </div>
</template>

<script>
import { Button, FormBuilder } from "@cldcvr/flow";

export default {
  name: "ResetPasswordForm",
  components: {
    FormBuilder,
    Button
  },
  data() {
    return {
      isLoading: false,
      resetPasswordError: false,
      resetPasswordErrorMsg: "",
      buttonType: "primary",
      fields: [
        {
          type: "email",
          id: "gdp-emailid",
          name: "emailid",
          label: "email",
          placeholder: "enter email id",
          rules: "required"
        },
        {
          type: "text",
          id: "gdp-vcode",
          name: "vcode",
          label: "verification code",
          placeholder: "enter verification code",
          rules: "required"
        },
        {
          type: "password",
          id: "gdp-password",
          name: "password",
          label: "password",
          placeholder: "enter password",
          rules: "required"
        }
      ]
    };
  },
  computed: {},
  methods: {
    async submit({ valid, values }) {
      if (!valid) return;
      this.isLoading = true;
      try {
        await this.$store.dispatch("user/resetPassword", {
          username: values.emailid,
          passwordResetCode: values.vcode,
          newPassword: values.password
        });
        // if (res.nextStep && res.nextStep == "NEW_PASSWORD_REQUIRED") {
        //   await this.$store.dispatch("user/newLoginPassword", {
        //     username: values.username,
        //     loginSession: res.loginSession,
        //     newPassword: "Cldcvr@!23",
        //   });
        // }
        this.buttonType = "success";
        this.resetPasswordError = true;
        this.resetPasswordErrorMsg =
          "Password has been reset. Go to Login page";
      }
      catch (err) {
        console.error(err.message);
        this.buttonType = "error";
        this.resetPasswordError = true;
        this.resetPasswordErrorMsg = err.message;
        if (err.code === "InvalidPasswordException") {
          this.signUpErrorMsg =
            "Password did not conform with policy: password should contain uppercase, lowercase, special character, number and minimum length is 8";
        }
      }
      finally {
        this.isLoading = false;
      }
    },
    async reviewAndSubmit() {
      await this.$refs.resetPasswordForm.validateAndSubmit(); // FORM Builder emits submit event
    },
    redirectToLogin() {
      this.$router.push("/"); // FORM Builder emits submit event
    }
  }
};
</script>

<style scoped>
.margin-top-20 {
  margin-top: 20px;
}
</style>
