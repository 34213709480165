<template>
  <div class="flow-date-time-picker-container">
    <DatePicker v-model="innerValue" class="flow-date-time-picker">
      <template #default="{ inputValue, togglePopover }">
        <input
          :value="inputValue"
          :placeholder="placeholder"
          class="flow-date-time-picker-input"
          readonly
          @click="togglePopover()"
        />
      </template>
    </DatePicker>
  </div>
</template>
<script>
import { DatePicker } from "v-calendar";
// import { Icon } from "@cldcvr/flow";

export default {
  name: "DateTimePicker",

  components: {
    DatePicker,
    // Icon,
  },
  props: {
    value: {
      type: [Object, Date],
      default: () => null, //new Date()
    },
    placeholder: {
      type: String,
      default: "Test placeholder",
    },
  },
  computed: {
    innerValue: {
      // getter
      get() {
        return this.value;
      },
      // setter
      set(newValue) {
        /**
         * Whenever value is updated.<br/>
         * use 'value.sync' in parent component to achieve 2 way binding.
         *
         * @event update:value
         */
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>

<style >
div.flow-date-time-picker-container {
  display: flex;
  height: 36px;
}
div.flow-date-time-picker-container .flow-date-time-picker-icon {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid var(--dashboard-element-light);
}
div.flow-date-time-picker-container .flow-date-time-picker {
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--dashboard-input);
}
div.flow-date-time-picker-container .flow-date-time-picker:focus-within {
  box-shadow: 0 0 0 1px var(--dashboard-primary);
}
div.flow-date-time-picker-container .flow-date-time-picker .vc-container {
  --text-sm: 12px;
  --text-lg: 16px;
  --white: var(--dashboard-element-light);
  --gray-400: var(--dashboard-border-light);
  --gray-500: var(--dashboard-font-light);
  --gray-800: var(--dashboard-font-dark);
  --gray-900: var(--dashboard-font-dark);
  --blue-600: var(--primary-300);
}
div.flow-date-time-picker-container .flow-date-time-picker-input {
  height: 36px;
  background-color: inherit;
  outline: none;
  appearance: unset !important;
  background-image: none !important;
  border: none;
  color: var(--dashboard-font-dark);
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  box-sizing: border-box;
  font-family: inherit;
  padding: 11px 12px 11px 12px;
}
div.flow-date-time-picker-container .flow-date-time-picker-input::placeholder {
  color: var(--dashboard-input-placeholder);
}
</style>