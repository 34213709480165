<template>
  <div>
    <FormBuilder ref="signupForm" :fields="fields" @submit="submit" />
    <div
      v-if="signUpError"
      class="flow-grid-column padding-10"
      label="cloud services"
    >
      {{ signUpErrorMsg }}
    </div>
    <Button
      :loading="isLoading"
      :type="buttonType"
      class="margin-top-20"
      fullWidth
      size="normal"
      @click="reviewAndSubmit"
    >
      Sign Up
    </Button>
  </div>
</template>

<script>
import { Button, FormBuilder } from "@cldcvr/flow";

export default {
  name: "SignUpEmailPasswordForm",
  components: {
    FormBuilder,
    Button,
  },
  data() {
    return {
      isLoading: false,
      signUpError: false,
      signUpErrorMsg: "",
      buttonType: "primary",
      fields: [
        {
          type: "email",
          id: "gdp-emailid",
          name: "emailid",
          label: "email",
          placeholder: "enter email id",
          rules: { required: true },
        },
        {
          type: "password",
          id: "gdp-password",
          name: "password",
          label: "password",
          placeholder: "enter password",
          rules: { required: true },
        },
        {
          type: "text",
          id: "gdp-depot_id",
          name: "depot_id",
          label: "depot_id",
          placeholder: "enter depot id",
          rules: { required: true },
        },
      ],
    };
  },
  computed: {},
  methods: {
    async submit({ valid, values }) {
      if (!valid) return;
      this.isLoading = true;
      try {
        await this.$store.dispatch("user/signUpUsingEmailPassword", {
          username: values.emailid,
          password: values.password,
          attributes: [
            { Name: "email", Value: values.emailid },
            { Name: "custom:depoid", Value: values.depot_id },
          ],
        });
        // if (res.nextStep && res.nextStep == "NEW_PASSWORD_REQUIRED") {
        //   await this.$store.dispatch("user/newLoginPassword", {
        //     username: values.username,
        //     loginSession: res.loginSession,
        //     newPassword: "Cldcvr@!23",
        //   });
        // }
        this.buttonType = "success";
        this.signUpError = true;
        this.signUpErrorMsg =
          "Verification Link has been sent to Email address, you need to verify before login";
      } catch (err) {
        console.error(err.message);
        this.buttonType = "error";
        this.signUpError = true;
        this.signUpErrorMsg = err.message;
        if (err.code === "InvalidPasswordException") {
          this.signUpErrorMsg =
            "Password did not conform with policy: password should contain uppercase, lowercase, special character, number and minimum length is 8";
        }
      } finally {
        this.isLoading = false;
      }
    },
    async reviewAndSubmit() {
      await this.$refs.signupForm.validateAndSubmit(); // FORM Builder emits submit event
    },
    // redirectToLogin() {
    //   this.$router.push("/"); // FORM Builder emits submit event
    // },
  },
};
</script>

<style scoped>
.margin-top-20 {
  margin-top: 20px;
}
</style>
