<template>
  <div>
    <FormBuilder ref="forgotPasswordForm" :fields="fields" @submit="submit" />
    <div
      v-if="forgotPasswordError"
      class="flow-grid-column padding-10"
      label="cloud services"
    >
      {{ forgotPasswordErrorMsg }}
    </div>
    <Button
      :loading="isLoading"
      :type="buttonType"
      class="margin-top-20"
      fullWidth
      size="normal"
      @click="reviewAndSubmit"
    >
      Send Verification code
    </Button>
    <Button
      :loading="false"
      :type="loginbuttonType"
      class="margin-top-20"
      size="normal"
      @click="redirectToLogin"
    >
      Go to Login Page
    </Button>
    <Button
      :disabled="disabled"
      :loading="false"
      class="margin-top-20"
      fullWidth
      size="normal"
      type="secondary"
      @click="redirectToReset"
    >
      Go to Reset Password
    </Button>
  </div>
</template>

<script>
import { Button, FormBuilder } from "@cldcvr/flow";

export default {
  name: "ForgotPasswordForm",
  components: {
    FormBuilder,
    Button
  },
  data() {
    return {
      isLoading: false,
      forgotPasswordError: false,
      forgotPasswordErrorMsg: "",
      buttonType: "primary",
      disabled: true,
      fields: [
        {
          type: "email",
          id: "gdp-emailid",
          name: "emailid",
          label: "email",
          placeholder: "enter email id",
          rules: "required"
        }
      ]
    };
  },
  computed: {},
  methods: {
    async submit({ valid, values }) {
      if (!valid) return;
      this.isLoading = true;
      try {
        await this.$store.dispatch("user/forgotPassword", {
          username: values.emailid
        });
        // if (res.nextStep && res.nextStep == "NEW_PASSWORD_REQUIRED") {
        //   await this.$store.dispatch("user/newLoginPassword", {
        //     username: values.username,
        //     loginSession: res.loginSession,
        //     newPassword: "Cldcvr@!23",
        //   });
        // }
        this.buttonType = "success";
        this.forgotPasswordError = true;
        this.forgotPasswordErrorMsg =
          "Verification code has been sent to Email address, Click on 'Go to Reset Password' to reset password";
        this.disabled = false;
      }
      catch (err) {
        console.error(err.message);
        this.buttonType = "error";
        this.forgotPasswordError = true;
        this.forgotPasswordErrorMsg = err.message;
        this.disabled = false;
      }
      finally {
        this.isLoading = false;
      }
    },
    async reviewAndSubmit() {
      await this.$refs.forgotPasswordForm.validateAndSubmit(); // FORM Builder emits submit event
    },
    redirectToLogin() {
      this.$router.push("/"); // FORM Builder emits submit event
    },
    redirectToReset() {
      this.$router.push("/reset-password"); // FORM Builder emits submit event
    }
  }
};
</script>

<style scoped>
.margin-top-20 {
  margin-top: 20px;
}
</style>
