<template>
  <div class="flow-login-container">
    <div class="flow-login-logo">
      <img src="./../../../assets/logo-new.png" alt="Goodpack" height="100px" />
    </div>
    <div class="flow-login-box">
      <slot name="header">
        <h2 class="fc-dark margin-bt-24">{{ header }}</h2>
      </slot>
      <Section padding="none">
        <slot />
      </Section>
      <div class="center-of-container">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Section } from "@cldcvr/flow";

export default {
  name: "AuthWrapper",
  components: {
    Section,
  },
  props: {
    header: {
      type: String,
      required: false,
      default: "",
    },
    alternateAuthText: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped>
div.flow-login-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 52px;
}
div.flow-login-container > div.flow-login-logo {
  margin-bottom: 40px;
}
div.flow-login-container > div.flow-login-logo > img {
  width: 236px;
  height: 39px;
}
div.flow-login-container > div.flow-login-box {
  padding: 24px;
  width: 448px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--dashboard-border);
  border-radius: 12px;
}
div.flow-login-container > div.flow-login-box .auth-text {
  text-align: center;
}
@media (max-width: 550px) {
  div.flow-login-container > div.flow-login-box {
    width: 350px;
  }

  .padding-lr-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
}
</style>
