<template>
  <component :is="slideoutName" />
</template>

<script>
import { mapGetters } from "vuex";
// import { ingestionStore } from "@/modules/ingestion/store";

export default {
  name: "TheSlideoutContainer",
  components: {
    // use dynamic import if slideout can only be viewed by an authenticated user.
    ProfileSlideout: () =>
      import(
        /* webpackChunkName: "ProfileSlideout" */ "./../dashboard/slideouts/ContainerDetailsSlideout.vue"
      ),
  },
  computed: {
    ...mapGetters({
      isSlideOutOpen: "dashboard/isSlideoutOpen",
    }),
    slideoutName() {
      let slideoutName = null;
      switch (true) {
        case this.isSlideOutOpen:
          slideoutName = "ProfileSlideout";
          break;
      }
      return slideoutName;
    },
  },
};
</script>
