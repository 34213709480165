import apiService from "../../services/api.service";
import {SETTINGS} from "@/constants";

export async function openModal({commit}, selectedImage) {
    await commit("setSelectedImage", selectedImage);
    await commit("toggleModal", true);
}

export async function onImageSelect({commit}, selectedImage) {
    await commit("setSelectedImage", selectedImage);
}

export function closeModal({commit}) {
    commit("toggleModal", false);
}

/* eslint-disable */

// eslint-disable-next-line no-unused-vars
export async function downloadPDF({commit}, payload) {
    let data = JSON.stringify({
        barcode_id_timestamp: payload
    });

    const result = await apiService.customRequest(
        {
            method: "POST",
            url: SETTINGS.PDF_DOWNLOAD_FUNCTION_URL,
            data: data,
        }
    )

    return result.data;
}

// eslint-disable-next-line no-unused-vars
// export async function downloadPDF({commit}, payload) {
//     // const res = await apiService.post(
//     //     "/pdf-download",
//     //     {
//     //         barcode_id_timestamp: payload,
//     //     },
//     //     {timeout: 100000}
//     // );
//
//     const res = await apiService.customRequest(
//         {
//             method: "POST",
//             url: PDF_DOWNLOAD_FUNCION_URL,
//             data: {
//                         barcode_id_timestamp: payload,
//                     }
//         }
//     );
//     const URL = res.data;
//     return URL;
// }

// eslint-disable-next-line no-unused-vars
export async function downloadZip({commit}, payload) {
    const res = await apiService.post("/zip-download", payload);
    const URL = res.data;
    return URL;
}

export async function onSearch({commit}, payload) {
    commit("setNotFoundMsg", "");
    commit("togleIsNextEnable", true);
    commit("setPageSize", parseInt(payload.no_of_records_per_page));
    const res = await apiService.post("/search-metadata", payload);
    await commit("setSearchPayload", payload);
    const {start_key, results, barcode_not_found_msg} = res.data.body;
    if (Object.keys(start_key).length === 0) commit("togleIsNextEnable", false);
    await commit("setTableData", results);
    const startKeyArr = [{}];
    startKeyArr.push(start_key);
    await commit("setStartKey", startKeyArr);
    if (barcode_not_found_msg) commit("setNotFoundMsg", barcode_not_found_msg);
    return res;
}

export async function handleNextPage({commit, state}, offsetIndex) {
    const payload = state.searchPayload;
    const startKeys = state.startKey;
    payload["start_key"] = startKeys[offsetIndex];
    const res = await apiService.post("/search-metadata", payload);
    const {start_key, results} = res.data.body;
    commit("setTableData", results);
    if (Object.keys(start_key).length === 0) commit("togleIsNextEnable", false);
    startKeys.push(start_key);
    await commit("setStartKey", startKeys);
}

export async function handlePrevPage({commit, state}, offsetIndex) {
    const payload = state.searchPayload;
    const startKeys = state.startKey;
    payload["start_key"] = startKeys[offsetIndex];
    const res = await apiService.post("/search-metadata", payload);
    const {results} = res.data.body;
    commit("togleIsNextEnable", true);
    commit("setTableData", results);
}

export function openSlideout({commit}, slideoutDetails) {
    commit("setSlideoutDetails", slideoutDetails);
    commit("toggleSlideout", true);
}

export function closeslideout({commit}) {
    commit("setSlideoutDetails", {});
    commit("toggleSlideout", false);
}

export function updateSelectedRows({commit}, count) {
    commit("updateSelectedRows", count);
}

export function togleIsNextEnable({commit}, value) {
    commit("togleIsNextEnable", value);
}
