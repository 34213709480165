<template>
  <div v-if="isDev" class="chip_tag">UAT</div>
</template>

<script>
import { IS_DEV } from "@/constants";

export default {
  name: "IsDevChip",
  data() {
    return {
      isDev: IS_DEV,
    };
  },
};
</script>

<style scoped>
.chip_tag {
  display: inline-block;
  padding: 0 30px;
  height: 50px;
  font-size: 160%;
  font-weight: bold;
  color: #0066ff;
  line-height: 50px;
  border-radius: 25px;
  background-color: #d6dde6;
}
</style>
