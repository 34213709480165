<template>
  <ModalBox :open="isImageModalOpen" class="modalStyle" width="600px">
    <Slab :size="'large'" type="transparent">
      <template #primary-action>
        <span> {{ selectedImage.id }} </span>
      </template>
      <template #secondary-actions>
        <Button
          :disabled="pdfButton.loading"
          :type="pdfButton.type"
          class="margin-left-15"
          size="normal"
          @click="downloadPDF"
        >
          Download PDF
        </Button>
        <Icon
          class="padding-4"
          name="i-close"
          size="x-small"
          type="filled"
          @click="closeImageModal"
        ></Icon>
      </template>
    </Slab>
    <!-- <div class="display-flex"> -->
    <Slab>
      <template #primary-action>
        <img
          :src="selectedImage.value"
          class="padding-10 zoom"
          height="500px"
          width="900px"
        />
      </template>

      <div
        v-for="detail in slideoutDetails.details"
        :key="detail.key"
        class="flow-grid-column"
      >
        <div class="display-flex">
          <span class="min-width-150 align-text-left fc-light">
            {{ detail.key }}</span
          >
          <span> {{ detail.value }}</span>
        </div>
      </div>
      <div class="display-flex">
        <div
          v-for="(value, key) in slideoutDetails.images"
          :key="key"
          class="padding-10"
        >
          <img
            :alt="key"
            :class="key == selectedImage.key ? 'selected' : ''"
            :src="value"
            class="padding-10"
            height="200"
            width="200"
            @click="onImageSelect(key, value)"
          />
        </div>
      </div>
    </Slab>
  </ModalBox>
</template>

<script lang="ts">
import { Button, Icon, ModalBox, Slab } from "@cldcvr/flow";
import { mapGetters } from "vuex";

export default {
  name: "ImageModal",
  components: {
    Icon,
    Slab,
    ModalBox,
    Button,
  },
  data() {
    return {
      mWidth: 600,
      pdfButton: {
        loading: false,
        type: "primary",
      },
    };
  },
  computed: {
    ...mapGetters({
      isImageModalOpen: "dashboard/isImageModalOpen",
      slideoutDetails: "dashboard/getSlideoutDetails",
      selectedImage: "dashboard/getSelectedImage",
    }),
  },
  methods: {
    _downloadURI(uri) {
      var link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadPDF() {
      try {
        this.pdfButton.loading = true;
        const payload = {};
        const payloadArr = [];
        payload["Barcode1D"] = this.slideoutDetails.details[0].value;
        payload["Date_Time"] = this.slideoutDetails.details[1].value;
        payloadArr.push(payload);
        const URL = await this.$store.dispatch(
          "dashboard/downloadPDF",
          payloadArr
        );
        this._downloadURI(URL.body);
        this.pdfButton.type = "primary";
      } catch (err) {
        console.error(err);
        this.pdfButton.type = "error";
      } finally {
        this.pdfButton.loading = false;
      }
    },
    closeImageModal() {
      this.$store.dispatch("dashboard/closeModal");
    },
    onImageSelect(key, value) {
      const selectedImage = {
        key,
        value,
        id: value.split("/")[3],
      };

      this.$store.dispatch("dashboard/onImageSelect", selectedImage);
    },
  },
};
</script>

<style scoped>
.modalStyle {
  z-index: 211;
}

.display-flex {
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
  max-height: 500px;
  overflow-y: scroll;
}

.selected {
  background: var(--slideout-primary);
}
</style>
