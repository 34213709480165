<template>
  <div v-if="dpUserProfile">
    <VPopover
      :popover-class="'element-light'"
      container="div.flow-layout"
      placement="bottom"
    >
      <Button state="icon" type="primary">
        <Icon
          :effects="false"
          name="i-user"
          size="small"
          state="dark"
          type="filled"
        ></Icon>
      </Button>
      <template slot="popover">
        <div class="margin--20">
          <Slab
            :effects="false"
            border-type="solid"
            size="medium"
            type="bottom-border"
          >
            <div class="padding-rt-16">
              <div class="display-flex align-items-center flex-gap-8">
                <p class="fc-normal paragraph-1">
                  {{ dpUserProfile.userId || dpUserProfile.email }}
                </p>
              </div>
            </div>
          </Slab>

          <!--          download button for photo_qc app-->
          <Slab
            align-items="center"
            download
            href="./app.apk"
            size="medium"
            type="transparent"
          >
            <a download :href=app_name>
              <Icon name="i-download" size="small" state="dark" type="filled" />
              <Button
                :full-width="false"
                class="padding-20"
                size="small"
                state="text"
                type="default"
              >
                <span class="sentence-case">Download photo_qc app</span>
              </Button>
            </a>
          </Slab>

          <!--          download button for csv template-->
          <Slab
            align-items="center"
            download
            href="./app.apk"
            size="medium"
            type="transparent"
          >
            <a download href="/assets/template.csv">
              <Icon name="i-file" size="small" state="dark" type="filled" />
              <Button
                :full-width="false"
                class="padding-20"
                size="small"
                state="text"
                type="default"
              >
                <span class="sentence-case">Download template csv</span>
              </Button>
            </a>
          </Slab>
          <!--          signup admin button for csv template-->
          <Slab
            v-if="checkAdmin"
            align-items="center"
            download
            href="./app.apk"
            size="medium"
            type="transparent"
          >
            <Icon
              name="i-login"
              size="small"
              state="dark"
              type="filled"
              @click="redirectToSignUp"
            />
            <Button
              :full-width="false"
              class="padding-20"
              size="small"
              state="text"
              type="default"
              @click="redirectToSignUp"
            >
              <span class="sentence-case">Create New User</span>
            </Button>
          </Slab>
          <Slab align-items="center" size="medium" type="transparent">
            <template #primary-action>
              <Icon
                name="i-logout"
                size="small"
                state="dark"
                type="filled"
                @click="logoutUser"
              />
            </template>
            <Button
              :full-width="false"
              class="padding-0"
              size="small"
              state="text"
              type="default"
              @click="logoutUser"
            >
              <span class="sentence-case">Sign out</span>
            </Button>
          </Slab>
        </div>
      </template>
    </VPopover>
  </div>
</template>
<script>
import { VPopover } from "v-tooltip";
import { Button, Icon, Slab } from "@cldcvr/flow";
import { UserService } from "../../services/storage.service";
import {SETTINGS} from "@/constants";

export default {
  name: "UserProfilePopover",
  components: {
    VPopover,
    Button,
    Icon,
    Slab,
  },
  data() {
    return {
      app_name: `/assets/${SETTINGS.APP_NAME}`,
    };
  },
  computed: {
    dpUserProfile() {
      return UserService.getUserProfile();
    },
    checkAdmin() {
      return UserService.getUserProfile().userId.toLowerCase() ===
        "PhotoQCAdmin@goodpack.com".toLowerCase();
    },
  },
  methods: {
    async logoutUser() {
      await this.$store.dispatch("user/handleLogOut");
    },
    async redirectToSignUp() {
      await this.$router.push("/signup");
      window.location.reload();
    },
  },
};
</script>
<style #scoped>
div.flow-switch-container {
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--dashboard-element-light);
}

/* div.flow-layout-navbar {
  height: 90px;
} */
.popover-inner {
  padding: 0px;
}
</style>
